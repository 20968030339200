<template>
  <div class="home">
    <h1>Manager Factory Workers permission</h1>
    <a-button type="primary" @click="createWorkerMode = !createWorkerMode">
      <span v-if="createWorkerMode == false" > Add Worker </span>
      <span v-if="createWorkerMode == true" >  Back </span>
    </a-button>
    <a-divider />
    <a-row v-if="createWorkerMode == false"> 
      <a-col :span="12">
        <h3> Worker List </h3>
        <input placeholder="input search text" style="width: 200px" v-model="searchQuery" />
        <div
          style="height: max-content;  overflow-y: scroll;
                  height:600px;  background-color: none;">
          <a-card
            hoverable
            style="width: 400px;margin:3px;padding:12px"
            @dragover="allowDrop"
            v-for="factoryuser in filteredWorker"
            @drop="drop($event,factoryuser,'user')"
            :key="factoryuser.id">
            <a-card-meta :title="factoryuser.WorkerID" description="">
              <template #avatar>
                <a-avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
              </template>
            </a-card-meta>
            <div>
              Barcode: 
              <a-tag color="pink" style="margin-bottom:10px">
                {{ factoryuser.Barcode }}
              </a-tag>
              <br>
              List of permission:
              <a-badge
                @click="deletePermission(aPermission,factoryuser)"
                :count="getDescription(aPermission)"
                :number-style="{ backgroundColor: '#52c41a' }"
                v-if="factoryuser.Permission"
                v-for="aPermission in factoryuser.Permission"
                :key="aPermission"/>
            </div>
            <div>
              <a-divider />
              <a-space>
                <a-button danger @click="deleteWorker(factoryuser)">Delete</a-button>
                <a-button type="primary" @click="generateBarcode(factoryuser)">Update Barcode</a-button>
              </a-space>
            </div>
          </a-card>
        </div>
      </a-col>
      <a-col :span="12">
        <!-- <a-card
          style="width: 300px;z-index:10000"
          v-for="permission in workersPermissionList"
          @dragstart="dragStart($event,permission.key,'permission')"
          :key="permission.key"
          @dragover="allowDrop"
          draggable="true"
        >
          <p>{{ getDescription(permission.key) }}</p>
        </a-card> -->
        <h3>Available Permission</h3>
 
        <a-tag
          v-for="permission in workersPermissionList"
          @dragstart="dragStart($event,permission.key,'permission')"
          :key="permission.key"
          @dragover="allowDrop"
          draggable="true"
          color="#87d068"
          style="margin-bottom:15px;padding:9px;"
        >
          {{ getDescription(permission.key) }}
        </a-tag>
      </a-col>
    </a-row>
    <!-- create worker form -->
    <a-row v-if="createWorkerMode == true"> 
      <a-col :span="12">
        <div>
          <a-form 
            @submit="createWorker" 
            :rules="rules"  
            :model="formData"
          >
            <a-form-item name="WorkerID">
              <a-input
                label="Worker ID"
                size="large"
                type="text"
                v-model:value="formData.WorkerID" 
                :placeholder="$t('Worker ID')"
              >
                <template #prefix>
                  <UserOutlined :style="{ color: 'rgba(0,0,0,.25)' }" />  
                </template>
              </a-input>
            </a-form-item>
            <a-form-item name="WorkerName">
              <a-input
                label="Worker Name"
                size="large"
                type="text"
                v-model:value="formData.WorkerName"
                :placeholder="$t('Worker Name')"
              >
                <!-- <template #prefix>
                  <LockOutlined :style="{ color: 'rgba(0,0,0,.25)' }" />
                </template> -->
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-button
                type="primary"
                html-type="submit"
                :disabled="formData.WorkerID === '' || formData.WorkerName === ''"
              >
                Create
              </a-button>
            </a-form-item>
          </a-form>
        </div>
      </a-col>
      <a-col :span="12">
      </a-col>
    </a-row>
  </div>
</template>
<script>
  //import { defineComponent } from "vue";
  import {
    UserOutlined,
    EditOutlined,
    UploadOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    LoginOutlined,
    LogoutOutlined,
    TableOutlined,
    UserSwitchOutlined,
    EllipsisOutlined,
    SettingOutlined
  } from '@ant-design/icons-vue'
  // import Draggable from 'vue3-draggable';
  // import draggable from 'vuedraggable'
  import { getAllRmaWorkers } from "@/services/rmaRepositories";
  import useRmaRepositories from "@/composables/useRmaRepositories";
  import { notification } from 'ant-design-vue';
  import { onMounted, reactive } from "vue";

 

  export default {
    components: {
      UserOutlined,
      UploadOutlined,
      MenuUnfoldOutlined,
      MenuFoldOutlined,
      LoginOutlined,
      LogoutOutlined,
      TableOutlined,
      UserSwitchOutlined,
      SettingOutlined,
      EditOutlined,
      EllipsisOutlined,
  
  
    },

    data() {
      return {
        workersPermissionList : 
          [
            {
              key:"#INTRANET::FRONT_view sidebar group rma operation",
              name:"View RMA Operation Sidebar Main Menu"
            },
            {
              key:"#INTRANET::FRONT_view (factory) receive from shop",
              name:"View receive from shop"
            },
            {
              key:"#INTRANET::FRONT_view (factory) send to repair",
              name:"View send to repair"
            },
            {
              key:"#INTRANET::FRONT_view (factory) send to planting",
              name:"View send to plating"
            },
            {
              key:"#INTRANET::FRONT_view (factory) receive from repair",
              name:"View receive from repair"
            },
            {
              key:"#INTRANET::FRONT_view (factory) logistic ship out",
              name:"View logistic ship out"
            },
            {
              key:"#INTRANET::FRONT_view (factory) (assembly) process",
              name:"View assembly sidebar sub menu"
            },
            {
              key:"#INTRANET::FRONT_view (factory) (assembly) pen plating process",
              name:"View assembly pen plating process"
            },
            {
              key:"#INTRANET::FRONT_view (factory) (assembly) setting stone",
              name:"View assembly setting stone"
            },
            {
              key:"#INTRANET::FRONT_view (factory) (assembly) setting pearl",
              name:"View assembly setting pearl"
            },
            {
              key:"#INTRANET::FRONT_view (factory) QC final",
              name:"View QC final"
            },
            {
              key:"#INTRANET::FRONT_view (factory) repaired",
              name:"View repaired item"
            },
            {
              key:"#INTRANET::FRONT_view (factory) (repair) operation group",
              name:"View repair operation sidebar sub menu"
            },
            {
              key:"#INTRANET::FRONT_view (factory) (repair) operation process",
              name:"View repair operation process"
            },
            {
              key:"#INTRANET::FRONT_view (factory) (repair) operation polishing process",
              name:"View repair operation polishing proces"
            },
            {
              key:"#INTRANET::FRONT_view (factory) (repair) QC process",
              name:"View repair QC Process"
            },
            {
              key:"#INTRANET::FRONT_view list all rma page for operation",
              name:"View RMA List"
            }
          ],
        factoryWorkers:[],
        searchQuery:"",
        formData:{
          WorkerID: '',
          WorkerName: '',
          Department: '',
          Barcode: ''
        },
        createWorkerMode:false

      }
    },
    async mounted() {
      await this.getAllRmaWorkersc()
    },
    setup() {
      // const searchQuery = ref("");
    },
    // const earchWorkerKey = ref("") 
    
    methods: {
      getDescription(key){
        const description = this.workersPermissionList.find(x => x.key === key)
        if(!description){
          return "-"
        }
        return description.name;
      },
      deleteWorker: async function (user) {
        // console.log(user);
        try {
          const response =  await useRmaRepositories.deleteWorker(user);
          this.factoryWorkers =  this.factoryWorkers.filter(x => x.id !== user.id)
        } catch (error) {
          
        }
      },
      createWorker: async function() {
        // console.log(this.formData)
        this.formData.Barcode =  Math.floor(100000 + Math.random() * 900000).toString()
        const response =  await useRmaRepositories.createWorker(this.formData);
        await this.getAllRmaWorkersc()
        this.createWorkerMode = false;
      },
      generateBarcode: async function(user){
        var confirm = window.confirm(`Update Barcode ?`);
        if(confirm){
          const num = Math.floor(100000 + Math.random() * 900000)
          // console.log(num)
          user.Barcode = num.toString()
          // console.log(user.Barcode)
          const response =  await useRmaRepositories.updateWorker(user);
        }
      },
      getAllRmaWorkersc :async function(){
        const response = await getAllRmaWorkers();
        response.data.forEach(element => {
          const split  = element.Permission.split(',')
          element.Permission = split
        });

        this.factoryWorkers = response.data;
      },
      allowDrop: function(event) {
        event.preventDefault();
      },
      // event from the drag and frop ui
      dragStart: function(event, data, dragType) {
        // console.log("drag start", data)
        event.dataTransfer.setData("dataTransfered", data);
        event.dataTransfer.setData("dragType", dragType);
      },
      drop: async function(event, data, dropType) {
        // console.log("drop", data)
        event.preventDefault();
        // 1) get the drag end type and data from event
        var dragData = event.dataTransfer.getData("dataTransfered");
        var dragType = event.dataTransfer.getData("dragType");
        // console.log("trasfer drop", dragData);
 
        if (dragType == "permission") {
          // console.log("Assiggn api call");
          // console.log(data);
          // console.log("trasfer drop", dragData);
          if(!data.Permission.includes(dragData)){
            data.Permission.push(dragData);
            const response =  await useRmaRepositories.updateWorker(data);//{"Barcode":data.Barcode,"Department":data.Department,}
          }else{
            // console.log("already exist")
          }
        }
        if (dragType == "user") {
          // console.log("Assiggn api user call");
        }
      },
      deletePermission:async function(key,user) {
       
        try {
          // console.log(key);
          // console.log(user);
          var confirm = window.confirm(`Delete permission ${key} ? `);
          if(confirm){
            user.Permission = user.Permission.filter(x => x != key);
            const response =  await useRmaRepositories.updateWorker(user);
          }
        }catch (error) {
          return error;
        }
      },
    },
    computed: {
      filteredWorker() {
        // console.log(790,this.searchQuery)
        if (!this.searchQuery) return this.factoryWorkers

        var find = function(object, search) {
          for (var property in object) {
            if (object.hasOwnProperty(property)) {
              if (typeof object[property] == "object") {
                find(object[property]);
              } else if (object[property].includes !== undefined) {
                if (object[property].includes(search)) return true;
              }
            }
          }
          return false;
        }

        return this.factoryWorkers.filter(user => {
          return find(user, this.searchQuery)
        })
      },
    }
  }
</script>

<style>
.ant-card-body {
  padding:10px;
}

.ant-divider-horizontal {
    margin: 10px 0;
}
</style>
